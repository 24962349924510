@import "variables";
@import "mixins";

.starSvg {
  width: 9px;
  height: 9px;

  @include pc {
    width: 11px;
    height: 11px;
  }

  &.active {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/static/svgs/common/initial_cost_score_active_star.svg");
  }

  &.inActive {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/static/svgs/common/initial_cost_score_inactive_star.svg");
  }
}
