@import "variables";
@import "mixins";

.noImage {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: $lightGray;
  border-radius: inherit;

  .imageWrapper {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    width: 52%;
    max-width: 134px;

    @include sp() {
      max-width: 96px;
    }
  }
}
