@import "variables";
@import "mixins";

.roomCard {
  position: relative;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;

  &.variable {
    @include pc() {
      font-size: 12px;
    }
    @include sp() {
      font-size: 10px;
    }
  }
  &.default {
    @include pc() {
      width: 270px;
      font-size: 13px;
    }
    @include sp() {
      width: 160px;
      font-size: 10px;
    }
  }
  &.small {
    @include pc() {
      width: 246px;
      font-size: 12px;
    }
    @include sp() {
      width: 150px;
      font-size: 10px;
    }
  }

  .infoTagWrap {
    position: absolute;
    display: flex;
    gap: 8px;
    top: 6px;
    left: 6px;
    z-index: 9;

    @include sp {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .newMark {
    display: flex;
    align-items: center;
    background-color: $pink;
    border-radius: 3px;
    color: $white;
    font-weight: 400;

    @include pc {
      top: 6px;
      left: 6px;
      height: 22px;
      padding: 0 7px;
      line-height: 22px;
      font-size: 14px;
    }
    @include sp {
      top: 6px;
      left: 6px;
      height: 17px;
      padding: 0 5px;
      line-height: 17px;
      font-size: 12px;
    }
  }

  .recommend {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    padding-right: 8px;
    background-color: $superLightPurple;
    color: $purple;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;

    svg {
      margin-top: -2px;
      scale: 0.5;
    }

    @include pc {
      font-size: 13px;
      font-weight: 700;

      svg {
        margin-top: -3px;
        scale: 0.6;
      }
    }
  }

  .favorite {
    position: absolute;
    z-index: 9;
    @include sp {
      top: 7px;
      right: 7px;
    }
    @include pc {
      top: 7px;
      right: 7px;
    }
  }

  .mainLink {
    display: block;
  }

  .imageWrapper {
    position: relative;
    width: 100%;

    @include pc() {
      height: 194px;
      border-radius: 5px 5px 0 0;
    }
    @include sp() {
      height: 155px;
      border-radius: 4px 4px 0 0;
    }

    img {
      @include pc() {
        border-radius: 5px 5px 0 0;
      }
      @include sp() {
        border-radius: 4px 4px 0 0;
      }
    }
  }

  .contentWrapper {
    padding: 0 12px;

    @include sp {
      padding: 0 8px;
    }
  }

  .price {
    margin: 8px 0 4px;

    @include sp {
      margin: 8px 0 0;
    }
  }
  .priceMain {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-right: 12px;

    @include sp {
      font-size: 13px;
      letter-spacing: 0;
      margin-right: 4px;
    }
  }
  .priceSub {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5;

    @include sp {
      letter-spacing: 0;
    }
  }

  .moneys {
    display: flex;
    align-items: flex-start;

    li {
      display: flex;
      align-items: center;
      min-width: 60px;
      font-weight: 600;
      line-height: 1.5;
      margin: 4px 0 6px;
      @include sp() {
        font-size: 11px;
        min-width: 40px;
      }
      & + li {
        margin-left: 5px;
      }

      &:before {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        text-align: center;
        line-height: 20px;
        font-weight: normal;
        text-decoration: none;
        margin-right: 4px;
        @include pc() {
          background-color: $lightGray;
        }
        @include sp() {
          width: 16px;
          height: 16px;
          background-color: $superLightGray2;
          line-height: 16px;
          font-size: 11px;
        }
      }
      &:nth-of-type(1):before {
        content: "敷";
      }
      &:nth-of-type(2):before {
        content: "礼";
      }
      &:nth-of-type(3):before {
        content: "仲";
      }

      &.isFree {
        color: $pink;
        font-weight: 600;
        &:before {
          background-color: rgba($pink, 0.1); //RGBは$pinkと同値
        }
      }
    }
  }

  .text {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    white-space: normal;
    margin-bottom: 6px;

    @include sp {
      font-size: 11px;
    }

    span {
      display: inline-block;
    }
  }

  .divider {
    width: calc(100% - 24px);
    height: 1px;
    background-color: $lightGray;
    margin: 8px auto 0;

    @include sp {
      width: calc(100% - 16px);
    }
  }

  .footer {
    padding-top: 8px;
  }
  .footerLink {
    display: flex;
    align-items: center;
    padding: 0 12px 8px;

    @include sp {
      padding: 0 8px 10px;
    }
  }
  .footerLinkIcon {
    display: block;
    width: 18px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("/static/svgs/detail/house.svg");

    @include sp {
      width: 16px;
      height: 16px;
    }
  }
  .footerLinkText {
    font-size: 12px;
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-top: 1px;
    margin-left: 4px;

    @include sp {
      font-size: 10px;
      margin-left: 2px;
    }
  }

  .emphasize {
    color: $green;
  }

  .freerentTag {
    background-color: rgba($pink, 0.1); //RGBは$pinkと同値
    border-radius: 3px;
    color: $pink !important;
    font-size: 12px;
    font-weight: 600 !important;
    padding: 4px;

    @include sp() {
      font-size: 10px;
      font-weight: 400 !important;
    }
  }

  .initialCostWrapper {
    margin-bottom: 6px;
  }

  .actionButton {
    font-size: 14px;
    font-weight: 400 !important;
    margin: 10px 0 5px;
    @include sp() {
      font-size: 13px;
      margin: 8px 0 2px;
    }
  }
}
