@import "variables";
@import "mixins";

.titleWrapper {
  position: relative;
}

.title {
  width: calc(100% - 80px);
}

.readAllLink {
  position: absolute;
  right: 0;
  bottom: 2px;
  color: $blue;
  font-size: 12px;
  margin-right: 12px;

  @include sp() {
    font-size: 10px;
    margin-right: 32px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 3px;
    right: -12px;
    width: 10px;
    height: 10px;
    border: 1px solid;
    border-color: $blue $blue transparent transparent;
    transform: rotate(45deg);

    @include sp() {
      width: 8px;
      height: 8px;
    }
  }
}

//=======================================
// TOP > 物件カルーセル > ガワ
//=======================================
.roomCarouselContent {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;

  @include sp() {
    width: 100%;
    padding: 2px 0;
  }
  @include pc() {
    width: 100%;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;
    overflow-x: unset;
  }
}

//=======================================
// TOP > 物件カルーセル > 物件アイテム
//=======================================
.roomCarouselContentItem {
  @include sp() {
    &:first-of-type {
      margin-left: 20px;
    }
    &:not(:first-of-type) {
      margin-left: 10px;
    }
    &:last-of-type {
      margin-right: 10px;
    }
  }
}
