@import "variables";
@import "mixins";

.favoriteButton {
  background-color: $white;
  border: none;
  border-radius: 50%;
  outline: none;
  padding: 0;
  cursor: pointer;

  svg {
    vertical-align: middle;
    margin-top: 2px;
  }
}

.small {
  width: 34px;
  height: 34px;

  svg {
    width: 22px;
    height: 18px;
  }

  @include sp {
    width: 27px;
    height: 27px;

    svg {
      width: 17px;
      height: 14px;
    }
  }
}

.middle {
  width: 40px;
  height: 40px;

  svg {
    width: 26px;
    height: 21px;
  }
}

.large {
  width: 47px;
  height: 47px;

  svg {
    width: 30px;
    height: 25px;
  }
}

.border {
  border: 1px solid $gray;
}
