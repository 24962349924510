@import "variables";
@import "mixins";

/*----------
  共通の要素
 ----------*/

.body {
  position: relative;
  display: block;
  background: none;
  border: none;
  text-align: center;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: opacity $hover-transition;

  @include pc {
    &:hover {
      opacity: $hover-opacity;
    }
  }

  span {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    letter-spacing: 1px;
  }

  svg {
    vertical-align: middle;
  }
}

/*--------------------
 描画のタイプ × 色のタイプ
 --------------------*/
.filled {
  color: white;

  &.blue {
    background-color: $blue;
    &.is3d {
      @include button3dStyle($blue);
    }
  }
  &.cv {
    color: $lightBlack;
    background-color: $campaignYellow;

    &.is3d {
      @include button3dStyle($campaignYellow);
    }
  }
  &.cv2 {
    color: $lightBlack;
    background-color: $campaignOrange;
    &.is3d {
      @include button3dStyle($campaignOrange);
    }
  }
  &.black {
    background-color: $darkBlack;
    &.is3d {
      @include button3dStyle($darkBlack);
    }
  }
  &.line {
    background-color: $lineForestGreen;
    &.is3d {
      @include button3dStyle($lineForestGreen);
    }
  }
}

.outlined {
  background-color: white;

  &.blue {
    color: $blue;
    border: 1px solid $blue;
  }
  &.black {
    color: $darkBlack;
    border: 1px solid $darkBlack;
  }
  &.transparent {
    background-color: transparent;
    color: $blue;
    border: 1px solid $blue;
  }
  &.is3d {
    @include button3dStyle($white);
  }
}

/*--------
   サイズ
 --------*/
.default {
  height: 50px;
  border-radius: 25px;
  line-height: 50px;

  @include pc() {
    width: 230px;
    font-size: 15px;
  }
  @include sp() {
    max-width: 100%;
    width: 300px;
    font-size: 15px;
  }
}

.thin {
  width: 100%;
  height: 40px;
  font-size: 13px;
  padding: 10px;
}

.big {
  width: 100%;
  height: 46px;
  font-size: 15px;
  padding: 12px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.02);
}

/*------
   装飾
 ------*/

.arrow {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    height: 10px;
    width: 10px;
    margin: auto;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    @include sp() {
      border-top: 2px solid white;
      border-right: 2px solid white;
    }
    @include pc() {
      border-top: 3px solid white;
      border-right: 3px solid white;
    }
  }

  &.outlined:after {
    @include sp() {
      border-top: 2px solid $blue;
      border-right: 2px solid $blue;
    }
    @include pc() {
      border-top: 3px solid $blue;
      border-right: 3px solid $blue;
    }
  }
}

.external {
  &:after {
    content: "";
    display: inline-block;
    width: 17px;
    height: 50px;
    margin: auto;
    margin-left: 10px;
    background: no-repeat center/contain url("/static/images/icon/link.png");
    vertical-align: bottom;
  }
}

.externalBlue {
  &:after {
    content: "";
    display: inline-block;
    width: 17px;
    height: 50px;
    margin: auto;
    margin-left: 10px;
    background: no-repeat center/contain url("/static/images/icon/link-blue.svg");
    vertical-align: bottom;
  }
}

/*------
   形状
 ------*/

.roundedRectangle {
  border-radius: 5px;
}

.square {
  height: 54px;
  width: 54px;
  border-radius: 5px;
}
