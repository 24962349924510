@import "variables";
@import "mixins";

.appContainer {
  display: block;
  background: linear-gradient(180deg, #2391dc 0%, rgba(35, 145, 220, 0) 223.26%) !important;
  position: relative;

  .containerLink::before {
    content: "";
    position: absolute;
    inset: 0;
  }

  @include pc {
    display: flex;
    justify-content: center;
    gap: 58px;
    padding-top: 30px;
    background: linear-gradient(68.31deg, #0881d3 -5.84%, #85c1ea 100.96%, #ffffff 161.63%);
  }
  .spIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 40px;
  }

  .spSubCatch {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    color: $white;
    letter-spacing: 1px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 28px;
      height: 1px;
      background-color: $white;
    }

    &::before {
      top: -10px;
      transform: rotate(-20deg);
    }

    &::after {
      bottom: -10px;
      transform: rotate(20deg);
    }
  }
  .spMainCatch {
    margin-top: 23px;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }

    .text {
      margin-top: 10px;
      font-size: 22px;
      font-weight: 600;
      color: $white;
      letter-spacing: 1px;
    }

    .subText {
      font-size: 17px;
      color: $white;
    }

    .borderText {
      @extend .text;

      margin-top: 0;
      padding: 1px 10px;
      border: 1px solid $white;
      border-radius: 5px;
    }
  }

  .crossIcon {
    position: relative;
    width: 14px;
    height: 14px;
  }
}

.pcContent {
  display: flex;
  align-items: center;
  flex-direction: column;

  .mainText {
    font-size: 22px;
    color: $white;
    letter-spacing: 1px;
  }

  .subText {
    font-size: 17px;
    color: $white;
    letter-spacing: 1px;
  }
}

.appIcon {
  position: relative;
  width: 44px;
  height: 44px;

  @include pc {
    width: 60px;
    height: 60px;
    margin-top: 20px;
  }
}

.phone {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.appLinks {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 28px 0 23px;
  border-bottom: 1px solid $gray;

  a {
    transition: $hover-transition;
    @include pc {
      &:hover {
        opacity: $hover-opacity;
      }
    }
  }
  @include pc {
    gap: 9px;
    border-bottom: none;
  }
  .appStore {
    position: relative;
    width: 125px;
    height: 46px;

    @include pc {
      width: 130px;
      height: 48px;
    }
  }

  .googlePlay {
    position: relative;
    width: 155px;
    height: 46px;

    @include pc {
      width: 158px;
      height: 48px;
    }
  }
}
