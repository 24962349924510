/****************************
 * Implementation of Mixins *
 ****************************/

/* ------------------
  Breakpoint Mixins
------------------ */

$breakpoints-up: (
  "pc": 750px,
);

$breakpoints-down: (
  "sp": 749px,
);

@mixin mq-up($size: pc) {
  @media screen and (min-width: #{map-get($breakpoints-up, $size)}) {
    @content;
  }
}

@mixin mq-down($size: sp) {
  @media screen and (max-width: #{map-get($breakpoints-down, $size)}) {
    @content;
  }
}

@mixin pc() {
  @include mq-up(pc) {
    @content;
  }
}

@mixin sp() {
  @include mq-down(sp) {
    @content;
  }
}

@mixin typeformContentWrap {
  height: 480px;
  border: 3px solid #2391dc;
  box-shadow: 0px 0px 20px rgba(35, 145, 220, 0.2);
  border-radius: 10px;

  @include pc {
    height: 610px;
  }
  // typeform の仕様上この指定が無いと contentWrap で指定した高さが反映されない
  > div {
    height: 100%;
  }
}

@mixin button3dStyle($color) {
  border: none;
  border-radius: 5px;
  background-color: $color;
  box-shadow: 0 3px 0 darken($color, 10%);

  &:active {
    transform: translateY(2px);
    box-shadow: 0 1px 0 darken($color, 10%);
  }
}
