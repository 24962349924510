@import "variables";
@import "mixins";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 91px;
  padding: 3px 5px 3px 0px;
  background: no-repeat center/contain
    url(/static/svgs/common/small_initial_cost_score_background.svg);

  @include pc {
    width: 103px;
    background: no-repeat center/contain url(/static/svgs/common/initial_cost_score_background.svg);
  }

  .label {
    white-space: nowrap;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    color: white;

    @include pc {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .starsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: -1px;
  }
}
