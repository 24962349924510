$darkBlue: #2e3948;
$blue: #2391dc;
$lightBlue: #5fafe6;
$superLightBlue: #e9f4fc;
$superLightBlue2: #f4f9fd;

$darkBlack: #19172c;
$black: #464646;
$lightBlack: #373737;
$darkGray: #969696;
$darkGray2: #6c6c6c;
$gray: #c8c8c8;
$lightGray: #e6e6e6;
$lightGray2: #c9c9c9;
$lightGray3: #cecece;
$superLightGray: #fafafa;
$superLightGray2: #f5f5f5;
$white: #fff;

$yellow: #facd32;
$darkYellow: #f4be00;
$darkYellow2: #ffc700;
$bronze: #bf8733;
$red: #f86e6e;
$pink: #ff6c6c;
$lightPink: #f86e6e1a;
$green: #007600;
$green2: #04ac00;
$lightGreen: #32ce2e;
$lightGreen2: #4dd26a;

$purple: #9148ff;
$superLightPurple: #efe5ff;

$gradation-standard: linear-gradient(270.96deg, #0881d3 10.04%, #03c4d0 96.43%);

$pc-width: 1140px;
$sp-padding-width: 20px;

$pc-sideMenu-max-width: 357px;

$pc-basic-hintMessage-width: 338px;

$pc-initialCostModal-header-height: 97px;
$pc-initialCostModal-body-max-height-space: 100px;

$pc-header-height: 80px;
$sp-header-height: 70px;

// campaign color
$campaignOrange: #ffa51e;
$campaignYellow: #ffdc41;
$campaignPink: #ffeef1;
$campaignDarkPink: #ff71ab;

// LINE color
$lineForestGreen: #06c755;

// font sizes
$font-sizes: 8, 10, 11, 12, 14, 15, 20, 25, 30;
$margin-sizes: 0, 4, 5, 10, 15, 20, 25, 30, 40, 50;

$hover-transition: 0.15s;

$hover-opacity: 0.7;
