@import "variables";
@import "mixins";

.updatePoint {
  display: inline-block;
  width: 9px;
  height: 9px;
  background-color: $lightGreen2;
  border-radius: 50%;
  margin-right: 4px;

  &.yellow {
    background-color: $yellow;
  }
}
