@import "variables";
@import "mixins";

$ball-margin: 4px;
$SP-ball-size: 16px;
$PC-ball-size: 20px;

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.ballPulseWrapper {
  width: ($SP-ball-size + $ball-margin * 2) * 3;
  margin: 20px auto 60px;
  @include pc {
    width: ($PC-ball-size + $ball-margin * 2) * 3;
  }
}

.ballPulse {
  & > div:nth-child(1) {
    animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  & > div:nth-child(2) {
    -webkit-animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  & > div:nth-child(3) {
    -webkit-animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  & > div {
    display: inline-block;
    width: $SP-ball-size;
    height: $SP-ball-size;
    background-color: $blue;
    margin: $ball-margin;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    @include pc {
      width: $PC-ball-size;
      height: $PC-ball-size;
    }
  }
}
